<!--费率信息-->
<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      :showAdd="$hasAccess('device.fee/add')"
      addName="新增费率"
      @getList="getList"
      @clearSearch="clearSearch"
      @addItemInTable="addOne"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.fee_name"
          size="small"
          clearable
          placeholder="请按费率名称查询"
        ></el-input>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="fee_name"
        title="费率名称"
        align="center"
        min-width="60px"
      />
      <vxe-table-column
              slot="table-item"
              field="unit_price"
              title="单价（元/度）"
              align="center"
              min-width="70px"
      />
      <vxe-table-column
              slot="table-item"
              field="short_one_hour"
              title="金额1"
              align="center"
              min-width="70px"
      />
      <vxe-table-column
        slot="table-item"
        field="short_two_hour"
        title="金额2"
        align="center"
        min-width="70px"
      />
      <vxe-table-column
              slot="table-item"
              field="short_three_hour"
              title="金额3"
              align="center"
              min-width="70px"
      />
      <vxe-table-column
        slot="table-item"
        field="short_four_hour"
        title="金额4"
        align="center"
        min-width="70px"
      />
      <vxe-table-column
              slot="table-item"
              field="short_five_hour"
              title="金额5"
              align="center"
              min-width="70px"
      />
      <vxe-table-column
              slot="table-item"
              field="short_six_hour"
              title="金额6"
              align="center"
              min-width="70px"
      />
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="100"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="editOne(row)"
            v-if="$hasAccess('device.fee/edit')"
          >编辑</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新增 -->
    <el-dialog
      title="新增费率"
      :visible.sync="addDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="800px"
    >

      <el-form
        :model="addDialogFormData"
        :rules="rules"
        size="mini"
        ref="addForm"
        label-width="160px"
        class="form"
      >
        <el-row>
          <el-col
            :xs="24"
            :sm="10"
          >
            <el-form-item
              label="费率名称"
              prop="fee_name"
            >
              <el-input
                v-model="addDialogFormData.fee_name"
                style="width: 100%;"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="单价(元/度)"
                    prop="short_two_hour"
            >
              <el-input-number
                      v-model="addDialogFormData.unit_price"
                      size="small"
                      style="width: 100%;"
                      controls-position="right"
                      :min="0"
                      :step="0.01"
                      :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item
                    label="金额1(元)"
                    prop="short_one_hour"
            >
              <el-input-number
                      v-model="addDialogFormData.short_one_hour"
                      size="small"
                      style="width: 100%;"
                      controls-position="right"
                      :min="0"
                      :step="0.01"
                      :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="金额2(元)"
              prop="short_two_hour"
            >
              <el-input-number
                v-model="addDialogFormData.short_two_hour"
                size="small"
                style="width: 100%;"
                controls-position="right"
                :min="0"
                :step="0.01"
                :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="金额3(元)"
              prop="short_three_hour"
            >
              <el-input-number
                v-model="addDialogFormData.short_three_hour"
                size="small"
                style="width: 100%;"
                controls-position="right"
                :min="0"
                :step="0.01"
                :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="金额4(元)"
              prop="short_four_hour"
            >
              <el-input-number
                v-model="addDialogFormData.short_four_hour"
                size="small"
                style="width: 100%;"
                controls-position="right"
                :min="0"
                :step="0.01"
                :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="金额5(元)"
              prop="short_five_hour"
            >
              <el-input-number
                v-model="addDialogFormData.short_five_hour"
                size="small"
                style="width: 100%;"
                controls-position="right"
                :min="0"
                :step="0.01"
                :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item
                    label="金额6(元)"
                    prop="short_six_hour"
            >
              <el-input-number
                      v-model="addDialogFormData.short_six_hour"
                      size="small"
                      style="width: 100%;"
                      controls-position="right"
                      :min="0"
                      :step="0.01"
                      :precision="2"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveAdd()"
          v-if="$hasAccess('device.fee/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑费率"
      :visible.sync="editDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="800px"
    >

      <el-form
        :model="editDialogFormData"
        :rules="rules"
        size="mini"
        ref="editForm"
        label-width="160px"
        class="form"
      >
        <el-row>
          <el-col
            :xs="24"
            :sm="10"
          >
            <el-form-item
              label="费率名称"
              prop="fee_name"
            >
              <el-input
                v-model="editDialogFormData.fee_name"
                style="width: 100%;"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="单价(元/度)"
                    prop="unit_price"
            >
              <el-input-number
                      v-model="editDialogFormData.unit_price"
                      size="small"
                      controls-position="right"
                      :min="0"
                      :step="0.01"
                      :precision="2"
                      style="width: 100%;"
              ></el-input-number>
            </el-form-item>
            <el-form-item
                    label="金额1(元)"
                    prop="short_one_hour"
            >
              <el-input-number
                      v-model="editDialogFormData.short_one_hour"
                      size="small"
                      controls-position="right"
                      :min="0"
                      :step="0.01"
                      :precision="2"
                      style="width: 100%;"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="金额2(元)"
              prop="short_two_hour"
            >
              <el-input-number
                v-model="editDialogFormData.short_two_hour"
                size="small"
                controls-position="right"
                :min="0"
                :step="0.01"
                :precision="2"
                style="width: 100%;"
              ></el-input-number>
            </el-form-item>
            <el-form-item
                    label="金额3(元)"
                    prop="short_two_hour"
            >
              <el-input-number
                      v-model="editDialogFormData.short_three_hour"
                      size="small"
                      controls-position="right"
                      :min="0"
                      :step="0.01"
                      :precision="2"
                      style="width: 100%;"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="金额4(元)"
              prop="short_four_hour"
            >
              <el-input-number
                v-model="editDialogFormData.short_four_hour"
                size="small"
                style="width: 100%;"
                controls-position="right"
                :min="0"
                :step="0.01"
                :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item
                    label="金额5(元)"
                    prop="short_five_hour"
            >
              <el-input-number
                      v-model="editDialogFormData.short_five_hour"
                      size="small"
                      controls-position="right"
                      :min="0"
                      :step="0.01"
                      :precision="2"
                      style="width: 100%;"
              ></el-input-number>
            </el-form-item>
            <el-form-item
                    label="金额6(元)"
                    prop="short_six_hour"
            >
              <el-input-number
                      v-model="editDialogFormData.short_six_hour"
                      size="small"
                      controls-position="right"
                      :min="0"
                      :step="0.01"
                      :precision="2"
                      style="width: 100%;"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveEdit()"
          :disabled="editDisabled"
          v-if="$hasAccess('device.fee/edit')"
        >保 存 编 辑
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/service/api'
import Tables from '@/components/tables'

export default {
  name: 'device-fee-list',
  components: {
    Tables
  },
  data () {
    return {
      // table信息
      tableName: '费率信息',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      // 编辑
      editDialogShow: false,
      editDialogFormData: {},
      editDialogFormOriData: {},
      editNeedData: ['fee_name', 'short_one_hour', 'short_two_hour',  'short_three_hour', 'short_four_hour',  'short_five_hour',  'short_six_hour', 'unit_price'],
      // 规则
      rules: {
        fee_name: [
          { required: true, message: '请输入费率名称', trigger: 'change' }
        ],
        unit_price: [
          { required: true, message: '单价', trigger: 'change' }
        ],
        short_one_hour: [
          { required: true, message: '金额1', trigger: 'change' }
        ],
        short_two_hour: [
          { required: true, message: '金额2', trigger: 'change' }
        ],
        short_three_hour: [
          { required: true, message: '金额3', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    editDisabled () {
      for (let item of this.editNeedData) {
        if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
          return false
        }
      }
      return true
    }
  },
  mounted () {

  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await API.Device.GetFeeList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {
        fee_id: ''
      }
    },
    // 重置form
    resetFormData () {
      this.addDialogFormData = {}
      this.editDialogFormData = {}
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 编辑
    async editOne (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        fee_id: row.fee_id
      }
      const res = await API.Device.GetFeeDetail(params)
      this.editDialogFormData = res
      if (res) {
        const { fee_id, fee_name,unit_price,short_one_hour, short_two_hour,  short_three_hour, short_four_hour,  short_five_hour,  short_six_hour } = res
        this.editDialogFormData = { fee_id, fee_name,unit_price, short_one_hour,short_two_hour, short_three_hour, short_four_hour,  short_five_hour,  short_six_hour }
        this.editDialogFormOriData = { fee_id, fee_name,unit_price,short_one_hour, short_two_hour, short_three_hour, short_four_hour,  short_five_hour,  short_six_hour}
      }
      this.editDialogShow = true
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
    },
    // 新增保存
    saveAdd () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData)
          }
          await API.Device.AddFee(params)
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          })
          this.closeDialog()
          this.getList(1, 10)
        }
      })
    },
    // 编辑保存
    saveEdit () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData)
          }
          await API.Device.EditFee(params)
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
